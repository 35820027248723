/*
 * Find user's location
 */	

var brochures, country, countryPhone, isEU, loc, specialOffers, storedLocale, localeData;
storedLocale = docCookies.getItem('getLocale');

/**
 * guestLocale - object used to get / maintain guests locale
 * @type {Object}
 */
var guestLocale = {
	/**
	 * checkData - checks getLocale data to confirm it is complete.
	 * @param  {object} data - users locale object
	 * @return {boolean}     - returns true is complete
	 */
	checkData: function (data) {
		localeData = JSON.parse(data);

		if (
			localeData.aircity !== undefined &&
			localeData.brochures !== undefined &&
			localeData.country !== undefined &&
			localeData.countryPhone !== undefined &&
			localeData.isEU !== undefined &&
			localeData.lastUpdated !== undefined &&
			localeData.primaryCurrency !== undefined &&
			localeData.specialOffers !== undefined &&
			localeData.status !== undefined
		) {
			// clean up data by replacing plus symbols (+) with spaces ( )
			var reg = /\+/g;
			if (localeData.city !== undefined) {
				localeData.city = localeData.city.replace(reg, ' ');
			}
			if (localeData.countryPhone !== undefined) {
				localeData.countryPhone = localeData.countryPhone.replace(reg, ' ');
			}

			// only defining these vars to support legacy functionality
			brochures = localeData.brochures;
			country = localeData.country;
			countryPhone = localeData.countryPhone;
			isEU = localeData.isEU;
			loc = localeData.status;
			specialOffers = localeData.specialOffers;

			// if loc is blank, apply to country value
			if (loc == '') {
				loc = country;
			}
			if (localeData.status == '') {
				localeData.status = localeData.country;
			}
			if(guestLocale.personalizedCountries.indexOf(localeData.country) > -1) {
                localeData.isIntl = false;
            } else {
                localeData.isIntl = true;
            }


			// add countries object to localeData
			localeData.countryName = guestLocale.countries;

			// set update digitalData
			!!window.digitalDataHelper && window.digitalDataHelper.addData('user', 'locale', localeData);

			!!window.guestAuth && window.guestAuth.debug && console.log('guestAuth: localeData', localeData);

			// locale data is complete
			return true;
		} else {
			// locale data not complete
			return false;
		}

	},
	/**
	 * getData - if guestLocale data is incomplete or not available, this will call the endpoint to get the guest's locale
	 */
	getData: function () {
		var currentTimestamp = new Date().getTime();
		jQuery.ajax({
			type: 'GET',
			url: $bookURL + '/captaincircle/getLocale.do?' + currentTimestamp,
			dataType: 'json',
			xhrFields: {
				withCredentials: true
			},
			global: false
		})
		.done(function (result) {
			if (result.status === 'success') {
				storedLocale = docCookies.hasItem('getLocale') ? docCookies.getItem('getLocale') : result.data;
				guestLocale.checkData(storedLocale);
			} else {
				guestLocale.setIntlData();
			}
		})
		.fail(function (req,error) {
			guestLocale.setIntlData();
		})
		.always(function (data) {
			jQuery(document).trigger('getLocale');
		});

	},
	/**
	 * setIntlData - should getData fail, this will set the user to int'l user
	 */
	setIntlData: function () {
		brochures = false;
		country = 'IN';
		countryPhone = '';
		isEU = false;
		loc = 'IN';
		specialOffers = false;
	},
	/**
     * personalizedCountries - list of country we support localization/personalization for
     * @type {Object}
     */
    personalizedCountries: [
        "US",
        "CA",
        "MX",
        "AU",
        "NZ",
        "UK",
        "GB",
        "SG",
        "HK"
	],
	
	/**
	 * countries - list of country descriptions
	 * @type {Object}
	 */
	countries: {
		"AX": "Aaland Islands",
		"AF": "Afghanistan",
		"AL": "Albania",
		"DZ": "Algeria",
		"AS": "American Samoa",
		"AD": "Andorra",
		"AO": "Angola",
		"AI": "Anguilla",
		"AQ": "Antarctica",
		"AG": "Antigua And Barbuda",
		"AR": "Argentina",
		"AM": "Armenia",
		"AW": "Aruba",
		"AU": "Australia",
		"AT": "Austria",
		"AZ": "Azerbaijan",
		"BS": "Bahamas",
		"BH": "Bahrain",
		"BD": "Bangladesh",
		"BB": "Barbados",
		"BY": "Belarus",
		"BE": "Belgium",
		"BZ": "Belize",
		"BJ": "Benin",
		"BM": "Bermuda",
		"BT": "Bhutan",
		"BO": "Bolivia",
		"BA": "Bosnia And Herzegowina",
		"BW": "Botswana",
		"BV": "Bouvet Island",
		"BR": "Brazil",
		"IO": "British Indian Ocean Territory",
		"BN": "Brunei Darussalam",
		"BG": "Bulgaria",
		"BF": "Burkina Faso",
		"BI": "Burundi",
		"KH": "Cambodia",
		"CM": "Cameroon",
		"CA": "Canada",
		"CV": "Cape Verde",
		"KY": "Cayman Islands",
		"CF": "Central African Republic",
		"TD": "Chad",
		"CL": "Chile",
		"CN": "China",
		"CX": "Christmas Island",
		"CC": "Cocos (Keeling) Islands",
		"CO": "Colombia",
		"KM": "Comoros",
		"CD": "Congo, Democratic Republic Of (Was Zaire)",
		"CG": "Congo, Republic Of",
		"CK": "Cook Islands",
		"CR": "Costa Rica",
		"CI": "Cote D'Ivoire",
		"HR": "Croatia",
		"CU": "Cuba",
		"CY": "Cyprus",
		"CZ": "Czech Republic",
		"DK": "Denmark",
		"DJ": "Djibouti",
		"DM": "Dominica",
		"DO": "Dominican Republic",
		"EC": "Ecuador",
		"EG": "Egypt",
		"SV": "El Salvador",
		"GQ": "Equatorial Guinea",
		"ER": "Eritrea",
		"EE": "Estonia",
		"ET": "Ethiopia",
		"FK": "Falkland Islands (Malvinas)",
		"FO": "Faroe Islands",
		"FJ": "Fiji",
		"FI": "Finland",
		"FR": "France",
		"FX": "France Metropolitan",
		"GF": "French Guiana",
		"PF": "French Polynesia",
		"TF": "French Southern Territories",
		"GA": "Gabon",
		"GM": "Gambia",
		"GE": "Georgia",
		"DE": "Germany",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GR": "Greece",
		"GL": "Greenland",
		"GD": "Grenada",
		"GP": "Guadeloupe/St Barth/St Martin",
		"GU": "Guam",
		"GT": "Guatemala",
		"GG": "Guernsey",
		"GN": "Guinea",
		"GW": "Guinea-Bissau",
		"GY": "Guyana",
		"HT": "Haiti",
		"HM": "Heard And Mc Donald Islands",
		"HN": "Honduras",
		"HK": "Hong Kong",
		"HU": "Hungary",
		"IS": "Iceland",
		"IN": "India",
		"ID": "Indonesia",
		"IR": "Iran (Islamic Republic Of)",
		"IQ": "Iraq",
		"IE": "Ireland",
		"IM": "Isle Of Man",
		"IL": "Israel",
		"IT": "Italy",
		"JM": "Jamaica",
		"JP": "Japan",
		"JO": "Jordan",
		"KZ": "Kazakhstan",
		"KE": "Kenya",
		"KI": "Kiribati",
		"KP": "Korea, Democratic People'S Republic Of",
		"KR": "Korea, Republic Of",
		"KW": "Kuwait",
		"KG": "Kyrgyzstan",
		"LA": "Lao People'S Democratic Republic",
		"LV": "Latvia",
		"LB": "Lebanon",
		"LS": "Lesotho",
		"LR": "Liberia",
		"LY": "Libyan Arab Jamahiriya",
		"LI": "Liechtenstein",
		"LT": "Lithuania",
		"LU": "Luxembourg",
		"MO": "Macau",
		"MK": "Macedonia",
		"MG": "Madagascar",
		"MW": "Malawi",
		"MY": "Malaysia",
		"MV": "Maldives",
		"ML": "Mali",
		"MT": "Malta",
		"MH": "Marshall Islands",
		"MQ": "Martinique",
		"MR": "Mauritania",
		"MU": "Mauritius",
		"YT": "Mayotte",
		"MX": "Mexico",
		"FM": "Micronesia, Federated States Of",
		"MD": "Moldova, Republic Of",
		"MC": "Monaco",
		"MN": "Mongolia",
		"ME": "Montenegro",
		"MS": "Montserrat",
		"MA": "Morocco",
		"MZ": "Mozambique",
		"MM": "Myanmar",
		"NA": "Namibia",
		"NR": "Nauru",
		"NP": "Nepal",
		"NL": "Netherlands",
		"AN": "Netherlands Antilles",
		"NC": "New Caledonia",
		"NZ": "New Zealand",
		"NI": "Nicaragua",
		"NE": "Niger",
		"NG": "Nigeria",
		"NU": "Niue",
		"NF": "Norfolk Island",
		"MP": "Northern Mariana Islands",
		"NO": "Norway",
		"OM": "Oman",
		"PK": "Pakistan",
		"PW": "Palau",
		"PS": "Palestinian Territory, Occupied",
		"PA": "Panama",
		"PG": "Papua New Guinea",
		"PY": "Paraguay",
		"PE": "Peru",
		"PH": "Philippines",
		"PN": "Pitcairn",
		"PL": "Poland",
		"PT": "Portugal",
		"PR": "Puerto Rico",
		"QA": "Qatar",
		"RE": "Reunion",
		"RO": "Romania",
		"RU": "Russian Federation",
		"RW": "Rwanda",
		"GS": "S.Georgia   S.Sandwich Island",
		"SH": "Saint Helena",
		"KN": "Saint Kitts And Nevis",
		"LC": "Saint Lucia",
		"PM": "Saint Pierre And Miquelon",
		"VC": "Saint Vincent And The Grenadines",
		"WS": "Samoa",
		"SM": "San Marino",
		"ST": "Sao Tome And Principe",
		"SA": "Saudi Arabia",
		"SN": "Senegal",
		"RS": "Serbia",
		"CS": "Serbia And Montenegro",
		"SC": "Seychelles",
		"SL": "Sierra Leone",
		"SG": "Singapore",
		"SK": "Slovakia",
		"SI": "Slovenia",
		"SB": "Solomon Islands",
		"SO": "Somalia",
		"ZA": "South Africa",
		"ES": "Spain",
		"LK": "Sri Lanka",
		"SD": "Sudan",
		"SR": "Suriname",
		"SJ": "Svalbard And Jan Mayen Islands",
		"SZ": "Swaziland",
		"SE": "Sweden",
		"CH": "Switzerland",
		"SY": "Syrian Arab Republic",
		"TW": "Taiwan",
		"TJ": "Tajikistan",
		"TZ": "Tanzania, United Republic Of",
		"TH": "Thailand",
		"TL": "Timor-Leste",
		"TG": "Togo",
		"TK": "Tokelau",
		"TO": "Tonga",
		"TT": "Trinidad And Tobago",
		"TN": "Tunisia",
		"TR": "Turkey",
		"TM": "Turkmenistan",
		"TC": "Turks And Caicos Islands",
		"TV": "Tuvalu",
		"UG": "Uganda",
		"UA": "Ukraine",
		"AE": "United Arab Emirates",
		"GB": "United Kingdom",
		"US": "United States",
		"UM": "United States Minor Outlying Islands",
		"UY": "Uruguay",
		"UZ": "Uzbekistan",
		"VU": "Vanuatu",
		"VA": "Vatican City State (Holy See)",
		"VE": "Venezuela",
		"VN": "Viet Nam",
		"VG": "Virgin Islands (British)",
		"VI": "Virgin Islands (U.S.)",
		"WF": "Wallis And Futuna Islands",
		"EH": "Western Sahara",
		"YE": "Yemen",
		"ZM": "Zambia",
		"ZW": "Zimbabwe",
		"JE": "Jersey",
	},
	/**
	 * isReady - fires callback when `localeData` exists
	 * @param  {Function} callback
	 */
	isReady: function(callback) {
		if (typeof callback == 'function') {
			if (!localeData) {
				$(document).on('getLocale', function () {
					callback();
				});
			}
			else {
				callback();
			}
		}
	}
}

/**
 * Check if locale object is complete or not. If incomplete, get the information
 * @param  {string} storedLocale - contents from getLocale cookie
 */

function getLocaleData() {
	if (storedLocale === undefined || storedLocale === null) {
		guestLocale.getData();
	} else {
		try {
			var isLocaleDataComplete = guestLocale.checkData(storedLocale);
			if (!isLocaleDataComplete) {
				throw new Error("localeData incomplete");
			}
		}
		catch (e) {
			guestLocale.getData();
		}
	}
}
getLocaleData();

jQuery(document).on('setLocale', function (event, app, isSignedIn) {
	// get and parse updated getLocale cookie data for logged in user
	storedLocale = docCookies.getItem('getLocale');
	localeData = JSON.parse(storedLocale);

	// set the pclSearch Ember object
	if (app === 'pclSearch' && window[app]) {
		window[app].set('airCityCode',localeData.aircity); // AirCityCode
		window[app].set('countryCode',localeData.country); // CountryCode
		window[app].set('state',localeData.state); // State
		window[app].set('zipCode',localeData.zipCode); // ZipCode
	}
});

// event listener to update locale data without reloading page
window.addEventListener('updateLocale', function () {
	storedLocale = docCookies.getItem('getLocale');
	getLocaleData();
});
