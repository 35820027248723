var guestAuth = {
    /**
     * debug - control the printing of logs in console
     * @param {boolean} setDebug - optional boolean to toggle debug on / off
     */

    debug: function (setDebug) {
        var guestAuthDebug = guestAuth.getName('pcl-guestauth-debug', guestAuth.env);
        
        if (typeof (setDebug) === 'boolean') {
            localStorage.setItem(guestAuthDebug, setDebug);
            console.log('guestAuth:', guestAuthDebug, setDebug);
        }

        return !!localStorage.getItem(guestAuthDebug) ? JSON.parse(localStorage.getItem(guestAuthDebug)) : false;
    },

    /**
     * env - current environment
     */
    env: hostDomain[sub_domain_substring[0]].envir,

    /**
     * eventListeners - events used to communicate messages between modal login windows vs parent window
     *                - setAuth to current state in global UI and single page apps
     *                - getAuth to retrieve status from global UI and single page apps
     */
    eventListeners: function () {
        guestAuth.debug() && console.log('guestAuth: eventListeners set');

        // listener from iframed login modal
        window.addEventListener('message', function (event) {
            if (event.origin !== $wwwURL) return;
            if (event.data.type === 'setAuth') {
                guestAuth.debug() && console.log('guestAuth: postMessage', event);
                guestAuth.updateWindow(event.data);
            } else if (event.data.isSignedIn) {
                guestAuth.debug() && console.log('guestAuth: postMessage', event);
                guestAuth.updateWindow({ type:'setAuth', status: event.data.isSignedIn, source: event.data.src });
            }
        }, false);

        // set auth listener
        window.addEventListener('setAuth', function (event) {
            guestAuth.debug() && console.log('guestAuth: set auth to', event);
            guestAuth.updateWindow(event.detail);
        }, false);

        // get auth listener - apps can use to retrieve status
        window.addEventListener('getAuth', function (event) {
            guestAuth.debug() && console.log('guestAuth: get auth to', event);
            var eventDetail = event.detail;
            eventDetail.status = guestAuth.status();
            guestAuth.updateWindow(eventDetail);
        }, false);

        // set auth listener
        window.addEventListener('unsetAuth', function (event) {
            guestAuth.debug() && console.log('guestAuth: unset auth to', event);
            guestAuth.logout(false, false);
        }, false);
    },

    /**
     * displayAuthEvent - dispatch events to update the global UI
     * @param {boolean} authStatus - current auth status
     * @param {string} displayEventType - event type
     */
    displayAuthEvent: function (authStatus, displayEventType, appType) {
        guestAuth.debug() && console.log('guestAuth: dispatch dispalyAuth event', authStatus, displayEventType);

        let globalHeader = document.getElementsByTagName('pcl-global-header');
        //let container = document.getElementById('container');

        switch (displayEventType) {
            case 'pageLoad':
                let mList = document.getElementsByTagName('html'),
                options = {
                    childList: true,
                    subtree: true
                };
                function mCallback(mutations) {
                  for (let mutation of mutations) {
                    if (mutation.addedNodes[0]?.nodeName === 'PCL-GLOBAL-HEADER') {
                      globalHeader[0].setAttribute('guest-auth', authStatus);
                      observer.disconnect();
                    }
                  }
                }
                let observer = new MutationObserver(mCallback);
                observer.observe(mList[0], options);
                break;
            case 'logoutBackground':
            case 'updateWindow':
                var refreshDelay = 0;
                !!window.digitalDataHelper && !!window.digitalDataHelper.routeLoad(true);

                // update header - test for new global component header to update it
                if (globalHeader && globalHeader.length > 0) {
                    globalHeader[0].setAttribute('guest-auth', authStatus);
                } else {
                    // default to legacy header and dispatch event to update it
                    window.dispatchEvent(new CustomEvent('displayAuth', {
                        bubbles: true,
                        detail: {
                            status: authStatus,
                            type: displayEventType,
                            appType: appType
                        }
                    }));
                }

                if (!authStatus && displayEventType === 'updateWindow') {
                    guestAuth.notice.logout(appType);
                    refreshDelay = 5000;
                }

                // close out modal for guest auth
                setTimeout(function () {
                    jQuery.magnificPopup.close();
                }, refreshDelay);
                break;

            case 'inactive':
                guestAuth.notice.inactive();
                break;

            case 'logoutInprogress':
                guestAuth.notice.logoutInprogress(appType);
                break;

            case 'logout':
                guestAuth.notice.logout(appType);
                break;

            case 'tokenRefresh':
                window?.jQuery.magnificPopup.close();
                break;
        }

    },

    /**
     * getName
     */
    getName: function (name, env) { 
        return env === 'prod' ? name : name + '-' + guestAuth.env;
    },

    /**
     * init - begins guestAuth logic by setting event listeners
     *      - starting token refresh timer if user is authenticated
     */
    init: function () {
        this.eventListeners();

        let authStatus = this.status();

        this.displayAuthEvent(authStatus, 'pageLoad');

        authStatus && this.tokenTimer();
    },

    /**
     * inactiveNotice - dispatches event to display inactive notice from global header. 
     *                - Will set a timer to automatically logout user if they do not respond within 1 minute
     */
    inactiveNotice: function () {
        // display inactivity notice
        this.displayAuthEvent(this.status(), 'inactive');

        // if user does not respond in one minute, logout for inactivity
        guestAuth.logoutTimer = setTimeout(function () { guestAuth.logout(true) }, 60000);
    },

    /**
     * fetchData - return fetch response to be used for token refresh and logout
     * @param {string} endpoint - endpoint url
     * @param {object} body - data to be sent in fetch body
     * @returns 
     */
    fetchData: function fetchData(endpoint, body) {
        var headers = {
            'Accept': '*/*',
            'Content-Type': 'application/json'
        };

        return fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: headers,
            body: typeof body === 'object' ? JSON.stringify(body) : ''
        }).then(function (response) {
            return response.json();
        });
    },

    /**
     * logout - will make the logout api call and dispatch event to update window
     * @param {boolean} fromInactivity - will be true when from not responding to inactive window
     * @param {boolean} inBackground - logout will be processed in background without notification to user. This is mainly used as clean up of incomplete auth to not interrupt the user.
     */
    logout: function (fromInactivity, inBackground) {
        var sccn = (!!window.digitalData && !!window.digitalData.user && !!window.digitalData.user.profile) ? window.digitalData.user.profile.ccn : docCookies.hasItem('sccn') ? docCookies.getItem('sccn') : '',
            endpoint = $bookURL + '/captaincircle/logoutFromCC.do',
            body = {
                ina: 'N',
                customerId: sccn,
                to: fromInactivity ? 'Y' : 'N'
            };
        
        // when user clicks the button, let's let  them know they are being logged out
        !fromInactivity && guestAuth.notice.logoutInprogress();

        this.fetchData(endpoint, body).then(function (data) {
            guestAuth.debug() && console.log('guestAuth:logout success', data);
        }).catch(function (error) {
            guestAuth.debug() && console.log('guestAuth:logout error', error);
            // should logout error out, manually log out user
            docCookies.hasItem('sccn') && docCookies.removeItem('sccn', '/', '.princess.com');
            var ccnExpName = guestAuth.getName('pcl-ccnexp', guestAuth.env);
            docCookies.hasItem(ccnExpName) && docCookies.removeItem(ccnExpName, '/', '.princess.com');
        }).finally(function () {
            // remove the 'sccn' & 'pcl-ccnexp' cookies from irrespective of the domain
            // to ensure the flow is not getting into the infite refresh
            // only in case they were not removed from logoutFromCC.do
            docCookies.hasItem('sccn') && docCookies.removeItem('sccn', '/');
            docCookies.hasItem('sccn') && docCookies.removeItem('sccn', window.location.pathname);
            var ccnExpName = guestAuth.getName('pcl-ccnexp', guestAuth.env);
            docCookies.hasItem(ccnExpName) && docCookies.removeItem(ccnExpName, '/');
            docCookies.hasItem(ccnExpName) && docCookies.removeItem(ccnExpName, window.location.pathname);
            
            guestAuth.debug() && console.log('guestAuth:logout finally, setAuth and clear inactiveTimer');
            // clear timer for inactiveNotice
            !guestAuth.inactiveTimer && clearTimeout(guestAuth.inactiveTimer);

            // set status for update window
            window.dispatchEvent(new CustomEvent('setAuth', {
                bubbles: true,
                detail: {
                    type: 'setAuth',
                    status: false,
                    source: window.location.pathname.split('/')[1],
                    event: (fromInactivity ? 'logoutTimeout' : inBackground ? 'logoutBackground' : 'logout')
                }
            }));
			// Remove pcl-guestAuthToken on logout
			sessionStorage.removeItem('pcl-guestAuthToken');
        });
    },

    /**
     * tokenRefresh - will make the keepAlive api call to refresh token
     */
    tokenRefresh: function () {
        guestAuth.debug() && console.log('guestAuth: refresh token');

        !!guestAuth.logoutTimer && clearTimeout(guestAuth.logoutTimer);

        var endpoint = $bookURL + '/captaincircle/keepAlive.do';
        this.fetchData(endpoint, 'post')
            .then(function (data) {
                guestAuth.debug() && console.log('guestAuth: tokenRefresh success', data);

                if (data.status === 'success') {
                    // update global UI after token has been refreshed
                    guestAuth.displayAuthEvent(true, 'tokenRefresh');
                    // reset token timer
                    guestAuth.tokenTimer();
                } else {
                    guestAuth.debug() && console.log('guestAuth: missing ccnExp or SCCN - force logout');
                    guestAuth.logout(false, true); // ccn exp cookie is missing, or results from server is error, log out user
                }    
            })
            .catch(function (error) {
                guestAuth.debug() && console.log('guestAuth: tokenRefresh error', error);

                // when token refresh fails, reset token timer to determine if another attempt to fresh is possible
                guestAuth.tokenTimer(true);
            });
    },

    /**
     * tokenTimer - logic of when to refresh token vs display inactive window. The goal is to prevent the token from expiring and breaking the user's experience.
     *            - when the page load is within 10 minutes of the token expiry time, automatically refresh
     *            - when expiry is more than 10 minutes, set a timer to display the inactive window at 3 minutes before expiry
     */
    tokenTimer: function (forceDisplay) {
        var ccnExpName = guestAuth.getName('pcl-ccnexp', guestAuth.env);
        var ccnExp = docCookies.getItem(ccnExpName);
        guestAuth.debug() && console.log('guestAuth: ccnExp', ccnExp);

        // clear previous timer, and refresh token timer
        !!this.inactiveTimer && clearTimeout(this.inactiveTimer);

        if (!!ccnExp && docCookies.hasItem('sccn')) {
            var tokenTimer = {};

            ccnExp = ccnExp.split('&');

            ccnExp.forEach(function (item) {
                var timestamp = item.split('=');
                tokenTimer[timestamp[0]] = timestamp[1] * 1000;
            });

            guestAuth.debug() && console.log('guestAuth: tokenTimer', tokenTimer);

            tokenTimer.inactiveTimestamp = tokenTimer.expiry - (1000 * 60) * 3; // timeleft before expiry
            tokenTimer.ttl = tokenTimer.inactiveTimestamp - Date.now(); // time to wait until displaying the inactive window

            if (forceDisplay) {
                // when force display is used, display inactive notice immediately, most likely due to token refresh failure
                this.inactiveNotice();
            } else if (tokenTimer.ttl / 60 / 1000 < 10) {
                // within 10 minutes of expiry at load time, automatically refresh token
                guestAuth.debug() && console.log('guestAuth: token expire < 10 minutes, refresh now', tokenTimer.ttl / 60 / 1000);
                this.tokenRefresh();
            } else {
                // more than 10 minutes of expiry at load time, set a count down timer
                guestAuth.debug() && console.log('guestAuth: token expire > 10 minutes, setTimeout for inactiveNotice in ', tokenTimer.ttl / 60 / 1000);
                guestAuth.inactiveTimer = setTimeout(function () {
                    guestAuth.inactiveNotice();
                }, tokenTimer.ttl);
            }
        } else {
            guestAuth.debug() && console.log('guestAuth: missing ccnExp or SCCN - force logout due to inactivy');
            this.logout(true); // ccn exp cookie is missing / user timed out, log out user
        }
    },

    /**
     * updateWindow - controls the logic of how to update the parent window reload vs passing data to SPAs
     * @param {boolean} data - 
     */
    updateWindow: function (data) {
        guestAuth.debug() && console.log('guestAuth: updateWindow', data);

        // start timer when setAuth status is true (user authenticated)
        data.status && this.tokenTimer();

        if (data.event === 'logout-timed-out') {
            guestAuth.debug() && console.log('guestAuth: updateWindow user has timeout, forward to login');
            location.href = $wwwURL + '/my-princess/login/';
        }

        var spas = ['brochures', 'cruise-search', 'my-princess', 'excursions', 'spa'];

        if (spas.includes(data.source)) {
            var guestProfile = !!window.guestProfile && window.guestProfile.getInfo();

            if (data.type === 'setAuth') {
                // set status for external apps
                window.dispatchEvent(new CustomEvent('updateLocale', {
                    bubbles: true
                }));

                // update dataLayer
                this.setStatus(data.status, localeData, guestProfile);

                // trigger event to update gloabl ui in window
                this.displayAuthEvent(data.status, data.event === 'logoutBackground' ? data.event : 'updateWindow', 'spa');

                if (data.source === 'excursions' && data.status) {
                    guestAuth.debug() && console.log('guestAuth: Forcing reload for excursions SPA');
                    setTimeout(function () {
                        window.location.reload();
                    }, 0);
                }

                // for logout in my-princess app, route user to login page
                if (!data.status && data.source === 'my-princess' && data.event !== 'logoutBackground') {
                    guestAuth.debug() && console.log('guestAuth: logout has occurred in my-princess, forward to login');
                    location.href = $wwwURL + '/my-princess/login/';
                }
            }
            
            // guest data
            var guestObj = {
                aircity: localeData.aircity,
                city: localeData.city,
                country: localeData.country,
                state: localeData.state,
                zip: localeData.zipCode,
                eccn: !!guestProfile ? guestProfile.ccn : docCookies.getItem('sccn'),
                guestStatus: !!guestProfile ? guestProfile.guestStatus : 'Prospect'
            };

            guestAuth.debug() && console.log('guestAuth: updateWindow for SPAs', guestObj);
            
            // pass data into cruise-search app
            if (['cruise-search', 'search-cruise'].includes(data.source)){ 
                if (window.pclSearch && guestObj.eccn !== null) {
                    // set the pclSearch Ember object for legacy
                    guestAuth.debug() && console.log('guestAuth: use old approaches for ember', window.pclSearch);
                    window.pclSearch.set('airCityCode', guestObj.aircity);
                    window.pclSearch.set('ccn', guestObj.eccn);
                    window.pclSearch.set('countryCode', guestObj.country);
                    window.pclSearch.set('guestStatus', guestObj.guestStatus);
                    window.pclSearch.set('isSignedIn', data.status);
                    window.pclSearch.set('zipCode', guestObj.zip);
                } else {
                    // this is cruise search react app - use new global window.setAuthStatus function
                    guestAuth.debug() && console.log('guestAuth: react app use new window.setAuthStatus', window.setAuthStatus);
                    typeof window.setAuthStatus === 'function' && window.setAuthStatus(data.status, guestObj);
                }
            }

            // pass data into brochure app
            if (data.source === 'brochures' && window.brochuresData) {
                guestAuth.debug() && console.log('guestAuth: window.brochuresData.getUserStatus', data.status, guestObj.eccn);
                window.brochuresData.getUserStatus(data.status, guestObj.eccn);
            }
        } else {
            guestAuth.debug() && console.log('guestAuth: updateWindow for legacy', data.source, data.status);

            // display logout confirmation before reload
            !data.status && this.displayAuthEvent(data.status, data.event, 'legacy');

            var refreshDelay = data.status ? 0 : 5000;
            setTimeout(function () {
                window.location.reload();
            }, refreshDelay);
        }
    },

    /**
     * setStatus - set auth status in dataLayer, SPAs in current window, global components
     */
    setStatus: function (status, locale, profile) {
        if (window.digitalDataHelper) {
            window.digitalDataHelper.addData('user', 'auth', { status: status });
            window.digitalDataHelper.addData('user', 'locale', locale);
            !!profile && window.digitalDataHelper.addData('user', 'profile', profile);
        }
    },

    /**
     * status - get current status from cookie
     * @returns {boolean} - return current auth status
     */
    status: function () {
        var ccnExpName = guestAuth.getName('pcl-ccnexp', guestAuth.env);
        var authStatus = (!!window.digitalData && !!window.digitalData.user && !!window.digitalData.user.auth) ? window.digitalData.user.auth.status : (docCookies.hasItem('sccn')) ? docCookies.hasItem('sccn') : docCookies.hasItem(ccnExpName);
        return authStatus;
    },

    notice: {
        inactive: function (appType) {
            var content = {
                event: 'inactive',
                title: '<svg aria-hidden="true" class="svg-icon svg-icon-warning"><use xlink:href="#icon-warning"></use></svg> Taking a break?',
                description: 'Due to inactivity, your browsing session is about to expire. To maintain security of your information, you will automatically be signed out.',
                primaryCTA: 'Continue my session',
                secondaryCTA: 'Log me out',
                appType: appType
            }
            guestAuth.notice.render(content);
        },
        logoutInprogress: function () {
            var content = {
                event: 'logoutInprogress',
                title: 'Logging out&hellip;',
                description: 'Please wait while we log you out.'
            }
            guestAuth.notice.render(content);
        },
        logout: function (appType) {
            var content = {
                event: 'logoutComplete',
                title: 'You have been logged out',
                description: 'Your window will update in 5 seconds.',
                secondaryCTA: 'Close window',
                appType: appType
            }
            guestAuth.notice.render(content);
        },
        render: function (content) {
            !!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth: notice', content);

            let template = `<div id="popupContainer" class="clearfix popupContainer tpl-v4 guestauth-notice">
                <header class="article-header">
                    <div class="article-title">
                        <div class="container">
                            <h3 class="align-center font-size-p10">${content.title}</h3>
                        </div>
                    </div>
                </header>
                <div class="article-container clearfix white-bg col-xs-pad-15-bottom col-xs-pad-15">
                    <div class="container col-xs-pad-15-bottom align-center">
                        <p>${content.description}</p>
                        <div class="col-xs-pad-15-top guestauth-notice-cta ${!content.primaryCTA ? 'hidden' : ''}">
                            <button type="button" class="button green-btn font-size-p5" id="guestauth-notice-primary-cta" data-guestauth-notice="${content.event}" data-guestauth-app-type="${content.appType}">${content.primaryCTA}</button>
                        </div>
                        <div class="col-xs-pad-10-top guestauth-notice-cta ${!content.secondaryCTA ? 'hidden': ''}">
                            <button type="button" class="plain-text-btn" id="guestauth-notice-secondary-cta" data-guestauth-notice="${content.event}" data-guestauth-app-type="${content.appType}">${content.secondaryCTA}</button>
                        </div>
                        <div class="col-xs-pad-15-top guestauth-notice-processing hidden">Processing&hellip;</div>
                    </div>
                </div>
            </div>`;

            window.dispatchEvent(new CustomEvent('guest-auth.notice', {
                detail: {
                    src: template,
                }
            }));
        }
    }
};

guestAuth.init();